<script lang="ts" setup>
const i18nHead = useLocaleHead({ addSeoAttributes: true })

useHead({
  title: () => `${$t('PxzuC9RPKQd8WVphjzWbw')} - ${$t('ZtzqRdD1HHSKd7-9mLEPS')}`,
  htmlAttrs: {
    lang: i18nHead.value.htmlAttrs!.lang,
  },
  link: [...(i18nHead.value.link || [])],
  meta: [
    ...(i18nHead.value.meta || []),
    { property: 'og:image', content: '/favicon.svg' },
    { name: 'description', content: () => `${$t('Sfpe36OhBW_FlyLPdxMJU')}，${$t('va2l6qnwD615aEMKBU2JQ')}` },
  ],
})
</script>

<template>
  <article class="feq-root">
    <div class="feq-content page-content-width">
      <h1>{{ $t('PxzuC9RPKQd8WVphjzWbw') }}</h1>
      <p>{{ $t('Sfpe36OhBW_FlyLPdxMJU') }}</p>
    </div>
  </article>
</template>

<style lang="scss" scoped>
@import './style/help-content-page';
</style>
